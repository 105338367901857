import React from 'react'
import student from '../../assets/faq/student.png'
import parent from '../../assets/faq/parent.png'
import company from '../../assets/faq/company.png'
import circle from '../../assets/faq/circle.png'
import SelectedBox from '../../assets/faq/selected.png'
import inActiveBox from '../../assets/faq/inactive.png'
import hoverBox from '../../assets/faq/notselected.png'



export default function RolesCardFaq({ index, role, roleClicked, selectedrole }: any) {
    const hadnleRoleClicked = () => {
        roleClicked(role)
    }
    return (
        <div key={index} className="faq-second-section-image-container" onClick={hadnleRoleClicked}>
            <div className='faq-second-section-roles-div'>
                <img src={circle} className="second-section-img-circle" />
                <img src={role.img} className="second-section-role-img" />
            </div>
            <p id={role.name} className={` second-section-role-title $`}>{role.text}</p>
            <div >
                {role.name != selectedrole ?
                    <img onMouseEnter={(e) => {
                        e.currentTarget.src = hoverBox;
                        // document.getElementById(role.name)

                    }} onMouseLeave={(e) => { e.currentTarget.src = inActiveBox }} src={inActiveBox} className="second-section-box-img" /> : <img src={SelectedBox} onMouseEnter={(e) => {
                        e.currentTarget.src = hoverBox
                    }} onMouseLeave={(e) => { e.currentTarget.src = SelectedBox }} className="second-section-box-img" />}

            </div>


        </div>

    )
}
