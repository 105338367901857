import { useNavigate } from "react-router-dom";
import {
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
  Youtube,
} from "../../../assets/icons";

import "./style.css";

function Footer() {
  const navigate = useNavigate();

  return (
    <>
      <div className="horizontal-line"></div>
      <footer className="footer_container">

        <div className="py-4">
          <div className="footer-main-container">
            <div className="octi-img-social">
              <img src="/octi-learn.svg" alt="" className=" octi-learn-img" />
              <p className="my-4 experience-text">Experience the future of learning.</p>
              <div className="icons d-flex  justify-content-between align-items-center social-media-icon">
                <div className=" ">
                  <a href="https://twitter.com/OctiLearn" target="_blank">
                    <Twitter width={25} height={19} />
                  </a>
                </div>
                <div className=" ">
                  <a href="https://www.instagram.com/octilearn" target="_blank">
                    <Instagram width={20} height={20} />
                  </a>
                </div>

                <div className="">
                  <a href="https://www.facebook.com/OctiLearn/" target="_blank">
                    <Facebook width={15} height={20} />
                  </a>
                </div>
                <div className="">
                  <a href="https://www.linkedin.com/company/octilearn/" target="_blank">
                    <Linkedin width={20} height={20} />
                  </a>
                </div>
                {/* <div className=" px-1">
                <a href="https://www.facebook.com/OctiLearn/" target="_blank">
                  <Facebook width={15} height={20} />
                </a>
              </div> */}
              </div>
            </div>

            {/* <div className="footer-details-main">
              <div >
                <h2 className="footer-list-header">company</h2>
                <h4 className="footer-list-headings">Home</h4>
                <h4 className="footer-list-headings">Ambassdors</h4>
                <h5 className="footer-list-headings">About us</h5>
              </div>
              <div >
                <h2 className="footer-list-header">company</h2>
                <h4 className="footer-list-headings">Home</h4>
                <h4 className="footer-list-headings">Ambassdors</h4>
                <h5 className="footer-list-headings">About us</h5>
              </div>
            </div> */}
            <div className="parent-beta">


              <div className="child-beta">
                <a className="beta-link" href="https://airtable.com/appp24e4POBGM4X9o/shrr3sIvV2Wjr2osF" target="_blank" rel="noopener noreferrer">
                  <div className="border-div">
                    <div className="beta-container">
                      <div className="beta-container-1">
                        <img className="inside-img" src="/insideBorder.png" alt="insideDiv" />
                        <img src="beta.png" className="beta" alt="beta" />
                      </div>
                    </div>
                    <div className="text-footer">
                      <p className="apply-text">Apply for early access now!</p>
                    </div>
                  </div>
                </a>

                <p className="footer-para">We are still tuning the product and would love your help. Join our Beta to help contribute.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>


  );
}

export default Footer;
{/* <div className="col-xl-3 col-lg-2 col-md-4 col-sm-3 col-6 d-flex flex-column justify-content-start align-items-md-center align-items-sm-end align-items-center pt-5 px-xl-1 order-lg-2 order-1">
<h5 className="footer-text mb-3 me-xl-0 me-3" onClick={() => navigate("/")}>Home</h5>
<ul className="list-unstyled ms-xl-3 ms-0">
  <li className="footer-text" onClick={() => navigate("/contact")}>
    Contact
  </li>
</ul>
</div>
<div className="col-xl-3 col-lg-2 col-md-4 col-sm-3 col-6 d-flex flex-column align-items-center justify-content-start pt-5 px-xl-1 order-lg-3 order-2">
<h5 className="footer-text mb-3 me-xl-5 ms-xl-2 ms-xl-0 me-lg-5 me-5" onClick={() => navigate("/ambassador")}>
  Ambassador
</h5>
<ul className="list-unstyled me-xl-5 me-lg-1 me-2">
  <li
    className="footer-text ms-xl-1 ms-xl-5"
    onClick={() => navigate("/faq")}
  >
    FAQ
  </li>
</ul>
</div> */}