import React from "react";// @ts-ignore
import  "./About.css";
import HeroSection from "../../components/About/HeroSection/Index";
import InspirationBehind from "../../components/About/InspirationBehind/Index";
import OurTeam from "../../components/About/OurTeam/Index";
import WhatWeDo from "../../components/About/WhatWeDo/Index";
import OurValue from "../../components/About/OurValue/Index";

export default function About(){

    return(
        <div className="About-Container">
            <div className="About-HeroSection">
                <HeroSection/>
            </div>
            <div className="About-InspirationBehind">
                <InspirationBehind/>
            </div>
           <div className="About-OurTeam">
            <OurTeam/>
           </div>
           <div className="About-WhatWeDo">
            <WhatWeDo/>
           </div>
           <div className="About-OurValue">
            <OurValue/>

           </div>


        </div>

        
    );


}