// @ts-ignore
import BackImg from "../../../../assets/About/BackImg1.svg";
import Frame from "../../../../assets/About/Frame1.png";
import "./LgTeamCard.css";
import Insta from "../../../../assets/About/Insta.png";
interface LgTeamCardProps {
  title: string;
  designation: string;
  description: string;
  image: any;
}
const defaultProps = {
  title: "",
  designation: "",
  description: "",
  image: {},
};

function LgTeamCard({
  title,
  designation,
  description,
  image,
}: LgTeamCardProps) {
  return (
    <div className="LgTeamCard-container">
      <div className="LgTeamCard-sub1">
        <img src={image} className="LgTeamCard-Img1" />
        <img src={Frame} className="LgTeamCard-Img2" />
      </div>
      <div className="LgTeamCard-sub2">
        <div className="LgTeamCard-sub2-sub1">
          <img src={BackImg} />
        </div>
        <div className="LgTeamCard-sub2-sub2">
          <div style={{display:"flex", alignItems:"center", justifyContent:"start",height:"50px",gap:"10px"}}>
            <p className="LgTeamCard-sub2-title">{title}</p>
            <a><img src={Insta}/></a>
          </div>
          <div>
            <p className="LgTeamCard-sub2-designation">{designation}</p>
            <img />
          </div>
          <div style={{display:"flex",width:"757px",height:"194px"}}>
            <p className="LgTeamCard-sub2-description">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
LgTeamCard.defaultProps = defaultProps;
export default LgTeamCard;
