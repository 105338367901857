
import "./OurValueCard.css";
import Line from "../../../../assets/About/OurValueLine.png";
import InnovationPic from "../../../../assets/About/InnovationPic.png";
interface OurValueCardProps{
   title:String,
   image:any
}

const defaultProps:Partial<OurValueCardProps>={
   title:"Student",
   image:InnovationPic
}
function OurValueCard({title,image}:OurValueCardProps){
    return(
    <div className="OurValueCard-container" >
        <div className="OurValueCard-BackGround"></div>
        <div className="OurValueCard-OverLay">
            <div className="OurValueCard-Image-div">
                <img src={image} className="OurValueCard-Image"/>
            </div>
            <div className="OurValueCard-title-div">
                <p className="OurValueCard-title-text">{title}</p>
            </div>
            <div className="OurValueCard-Line-div">
                <img src={Line} className="OurValueCard-Line"/>
            </div>
        </div>

    </div>
    );

}
OurValueCard.defaultProps=defaultProps
export default OurValueCard