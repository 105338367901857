import { useNavigate } from "react-router-dom";
import { Button } from "../../UI";
import { Hamburger } from "../../../assets/icons";
import "./style.css";

function Navbar() {
  const navigate = useNavigate();

  return (
    <>
      <div className="container-fluid nav_container p-md-5 py-5 px-3">
        <div className="logo_container">
          <img className="octi-learn-logo" src="octi-learn.svg" alt="" />
        </div>
        <div className="navigation_menu pt-md-4 pt-3">
          <ul className="mt-2 d-md-flex d-none">
            {/* <li onClick={() => navigate("/")} className='header-home'>Home</li> */}
            {/* <li onClick={() => navigate("/ambassador")} className='About-us'>About Us</li> */}
            {/* <li onClick={() => navigate("/ambassador")}>Ambassador</li> */}
            {/* <li onClick={() => navigate("/contact")}>Contact Us</li> */}
            {/* <li onClick={() => navigate("/faq")}>FAQ</li> */}
          </ul>
          <div className="icon_container hamburger-container d-md-none ">
            <Hamburger width={50} height={50} color="#E015A2BD" />
          </div>
        </div>
        <div>
          <a
            href="https://airtable.com/appp24e4POBGM4X9o/shrr3sIvV2Wjr2osF"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              className="secondary-button"
              hoverClassName="secondary-button:hover-effect" // Specify the hover class
              text="Join Waitlist"
              marginTop={40}
              customPaddingClass="extra-padding"
            />
          </a>
        </div>
      </div>
    </>
  );
}

export default Navbar;
