import React from "react";
import img2 from "../../../../assets/About/Frame2.png"; // @ts-ignore
import CardBack from "../../../../assets/About/BackImg2.svg";
import "./SmTeamCard.css";
import Insta from "../../../../assets/About/Insta.png";
interface SmTeamCardProps {
  title: string;
  image: any;
  designation: string;
  description: string;
}
const defaultProps: Partial<SmTeamCardProps> = {
  title: "John",
  description: "",
  designation: "",
  image: {},
};
function SmTeamCard({
  title,
  image,
  description,
  designation,
}: SmTeamCardProps) {
  return (
    <div className="SmTeamCard-Container">
      <div className="SmTeamCard-sub1">
        <img src={img2} className="SmTeamCard-Img2" />
        <img src={image} className="SmTeamCard-Img" />
      </div>
      <div className="SmTeamCard-sub2">
        <div className="SmTeamCard-sub2-sub1">
          <img src={CardBack} />
        </div>
        <div className="SmTeamCard-sub2-sub2">
          <div>
            <p className="SmTeamCard-sub2-title">{title}</p>
          </div>
          <div>
            <p className="SmTeamCard-sub2-desgination">{designation}</p>
          </div>
          <div className="SmTeamCard-Insta-Link">
            <a>
              <img src={Insta} />
            </a>
          </div>
          <div style={{ width: "252px" }}>
            <p className="SmTeamCard-sub2-description">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
SmTeamCard.defaultProps = defaultProps;
export default SmTeamCard;
