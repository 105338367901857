// @ts-ignore
import { ContentBox } from "../../home";
import SmTeamCard from "../Cards/SmTeamCard/Index";
import Team1 from "../../../assets/About/Team1.png";
import Team2 from "../../../assets/About/Team2.png";
import Team3 from "../../../assets/About/Team3.png";
import Team4 from "../../../assets/About/Team4.png";
import LgTeamCard from "../Cards/LgTeamCard/Index";
import './OurTeam.css'
const futureGenContent = {
  firstTitle: "Our Unique",
  subFirstTitle: " Team",
  secondTitle:
    "Inherent limitations of the traditional model leave individual learning ",
  subSecondTitle: "gaps unresolved",
  gradient: true,
  fontSize: 50,
  top: 0,
  lineTrue: true,
};

const Team = {
  Person2: {
    title: "John Carter",
    description:
      "Accelerate improvement with ongoing assessments that automatically target your learning gaps.",
    designation: "Designation",
    image: Team2,
  },
  Person3: {
    title: "John Carter",
    description:
      "Accelerate improvement with ongoing assessments that automatically target your learning gaps.",
    designation: "Designation",
    image: Team3,
  },
  Person4: {
    title: "John Carter",
    description:
      "Accelerate improvement with ongoing assessments that automatically target your learning gaps.",
    designation: "Designation",
    image: Team4,
  },
  Person1: {
    title: "John Carter",
    description:
      "Exasperated with the inflexibility, uniformity, and ineffectiveness of traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic platform to improve student learning—a setting where students could not only learn more effectively but also gain more practical",
    designation: "Designation",
    image: Team1,
  },
};

const Person1 = {
  title: "John Carter",
  description:
    "Accelerate improvement with ongoing assessments that automatically target your learning gaps.",
  designation: "Designation",
  image: Team2,
};

const OurTeam = (props: any) => {
  return (
    <div className="OurTeam-container">
      <div className="OurTeam-sub1" >
        <ContentBox {...futureGenContent} />
      </div>
      <div className="OurTeam-sub2"  data-aos="fade-left"
            data-aos-offset="400"
            data-aos-duration="3000"
            data-aos-easing="ease-in-shine">
        <LgTeamCard {...Team.Person1} />
      </div>
      <div className="OurTeam-sub3">
        <div data-aos="fade-up"
            data-aos-offset="400"
            data-aos-duration="1000">
        <SmTeamCard {...Team.Person2} />
        </div>
        <div data-aos="fade-up"
            data-aos-offset="400"
            data-aos-duration="1000"
            data-aos-delay="700">
        <SmTeamCard {...Team.Person3} />
        </div>
        <div data-aos="fade-up"
            data-aos-offset="400"
            data-aos-duration="1000"
            data-aos-delay="1000">
        <SmTeamCard {...Team.Person4} />
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
