import { useEffect, useState } from 'react';
import { buttonBackground, currentScreen } from '../../../theme';
import './style.css';

interface CardStyle {
  width: number;
  height: number;
  borderRadius: number;

  paddingTop: number;
  paddingBottom: number;
  paddingLeft: number;
  paddingRight: number;
  marginTop: number;
  marginBottom: number;
  marginLeft: number;
  marginRight: number;
  color: string;
  fontSize: number;
  image:string;
  title: string;
  subtitle: string;
  para: string;
  paraSize: number;
}

const defaultProps: Partial<CardStyle> = {
  height: 300,
  borderRadius: 10,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  marginTop: 0,
  marginRight: 0,
  marginBottom: 0,
  marginLeft: 0,
  fontSize: 23,
  paraSize: 18,
};

function Card({
  borderRadius,
  paddingTop,
  paddingBottom,
  paddingRight,
  paddingLeft,
  marginTop,
  height,
  color,
  fontSize,
  image,
  title,
  subtitle,
  para,
  paraSize,
}: CardStyle) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      <div className="col-xl-3 col-lg-3 col-md-3 col-6 card-parent-container" data-aos="zoom-in" data-aos-delay="300"  data-aos-duration="1000">
        <div
          className="card-container px-3"
        >
  
          <div className="title first-card-title ">
            <b>{title}</b>
          </div>
          
          <div className="title first-card-title">
            {subtitle}
          </div>

          <div className="card-bottom-line">           
          </div>

          <p
            className="para mt-2 px-4"
            style={{ fontSize: `${currentScreen * paraSize}px` }}
          >
            {para}
          </p>
        </div>
      </div>
    </>
  );
}
Card.defaultProps = defaultProps;
export default Card;
