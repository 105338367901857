import React from "react";
import ResponsiveImg from "../../UI/ResponsiveImage";
import ContentBox from "../ContentBox";
import { Button } from "../../UI";
import "./joinus.css";

function JoinUs() {
  const joinUsContent = {
    firstTitle: "Join OctiLearn Today!",
    secondTitle:
      "Experience the future of learning with our personalized and adaptive ecosystem",
    fontSize: 48,
    fontWeight: 600,
    paddingTop: 30,
  };
  return (
    <div className=" justify-content-center pb-5 pink-section">
      <div className="join-us position-relative mb-5 ">
        <ResponsiveImg
          width={250}
          position={{ top: "-75px", left: "25px", zIndex: "3" }}
          src="/join-section-1.png"
        />

        <ResponsiveImg
          width={230}
          position={{ top: "368px", right: "66px", zIndex: "3" }}
          src="/join-section-2.png"
        />

        <ResponsiveImg
          width={320}
          position={{ top: "278px", left: "18px", zIndex: "2" }}
          src="/join-section-3.png"
        />

        <ContentBox {...joinUsContent} top={20} />

        <div className="joinUs-bottom-line"></div>

        <ul className="d-flex list-unstyled m-0 p-0 list-styled">
          <li className="mx-3 text-white">
            <span>&#10003;</span>
            <p>Personalize Content</p>
          </li>
          <li className="mx-3 text-white">
            <span>&#10003;</span>
            <p>Learn Faster</p>
          </li>
          <li className="mx-3 text-white">
            <span>&#10003;</span>
            <p>Score Higher</p>
          </li>
        </ul>

        <div className="d-flex join-us-button justify-content-center mb-5">
          <a
            href="https://airtable.com/appp24e4POBGM4X9o/shrr3sIvV2Wjr2osF"
            target="_blank"
          >
            <Button
              className="secondary-button benefit-button2"
              text="Join Our Waitlist"
              marginTop={0}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default JoinUs;
