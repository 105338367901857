import { Button } from "../../UI";
import { ContentBox } from "../../home";
import "./OurValue.css";
import Line from "../../../assets/About/OurValueLine.png";
import OurValueCard from "../Cards/OurValueCard/Index";
import StudentCentric from "../../../assets/About/StudentCentricPic.png";
import InnovationPic from "../../../assets/About/InnovationPic.png";
import CouragePic from "../../../assets/About/CouragePic.png";
import PreserverancePic from "../../../assets/About/PreserverancePic.png";
import GrowthPic from "../../../assets/About/GrowthPic.png";
import ExcellencePic from "../../../assets/About/ExcellencePic.png";
import AuthenticityPic from "../../../assets/About/AuthenticityPic.png";
import { useEffect, useRef, useState } from "react";

const OurValues:any = {
  Value1: {
    title: "Innovation",
    description:" Exasperated with the inflexibility, uniformity, and in effectivenessof traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic",
    image: InnovationPic,
  },
  Value2: {
    title: "Student Centric",
    description:" Exasperated with the inflexibility, uniformity, and in effectivenessof traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic",
    image: StudentCentric,
  },
  Value3: {
    title: "Courage",
    description:" Exasperated with the inflexibility, uniformity, and in effectivenessof traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic",
    image: CouragePic,
  },
  Value4: {
    title: "Preserverance",
    description:" Exasperated with the inflexibility, uniformity, and in effectivenessof traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic",
    image: PreserverancePic,
  },
  Value5: {
    title: "Growth Mindset",
    description:" Exasperated with the inflexibility, uniformity, and in effectivenessof traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic",
    image: GrowthPic,
  },
  Value6: {
    title: "Excellence",
    description:" Exasperated with the inflexibility, uniformity, and in effectivenessof traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic",
    image: ExcellencePic,
  },
  Value7: {
    title: "Authenticity",
    description:" Exasperated with the inflexibility, uniformity, and in effectivenessof traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic",
    image: AuthenticityPic,
  },
};

const OurValue = (props: any) => {
  const [title,setTitle]=useState("Our Value");
  const [description,setDescription]=useState("Exasperated with the inflexibility, uniformity, and in effectivenessof traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic");
  const overlayRef = useRef(null);
  const [isOverlayVisible, setOverlayVisible] = useState(false);

  useEffect(() => {
    const options = {
      root: null, // viewport
      rootMargin: '0px',
      threshold: 0.1, // 0.5 means when 50% of the element is visible
    };

    const observer = new IntersectionObserver(handleOverlayIntersection, options);

    if (overlayRef.current) {
      observer.observe(overlayRef.current);
    }

    return () => {
      if (overlayRef.current) {
        observer.unobserve(overlayRef.current);
      }
    };
  }, []);

  const handleOverlayIntersection = (entries: any[]) => {
    entries.forEach((entry: { isIntersecting: any; }) => {
      if (entry.isIntersecting) {
        setOverlayVisible(true);
      } else {
        setOverlayVisible(false); // Reset visibility when not intersecting
      }
    });
  };


  const handleHover = (title: string, description: string) => {
    setTitle(title);
    setDescription(description);
  };

  return (
    <div className="OurValue-container" 
  >
      <div className="OurValue-BackSub">
        <div className="OurValue-title-div">
          <p className="OurValue-title-text">{title}</p>
        </div>
        <div className="OurValue-description-div">
          <p className="OurValue-description-text">
            {description}

          </p>
        </div>
        <div className="OurValue-Image-div">
          <img src={Line} />
        </div>
        <div className="OurValue-Button-div">
          <a href="" target="_blank">
            <Button
              className="secondary-button"
              text="Let's Get Started"
              marginTop={0}
            />
          </a>
        </div>
      </div>

      <div className="OurValue-OverLay" ref={overlayRef} >
      {isOverlayVisible && (
          <div>
            {Object.keys(OurValues).map((value) => (
              <div
                key={value}
                className={`OurValueCard${value.substring(5)}`}
                onMouseEnter={() => handleHover(OurValues[value].title, OurValues[value].description)}
              >
                <OurValueCard {...OurValues[value]} />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>

  );
};
export default OurValue;
