import { Button } from "../../components/UI";
import { forwardRef, useEffect, useRef, useState } from "react";

// import { useRef, useEffect } from "react";
import ResponsiveImg from "../../components/UI/ResponsiveImage";
import {
  HeroSection,
  IterativeProcess,
  ContentBox,
  Card,
} from "../../components/home";
import JoinUs from "../../components/home/JoinUs";
import ReviewCard from "../../components/home/PeopleLoveUs/ReviewCard/ReviewCard"; // @ts-ignore
import cardImg from "../../assets/home/Card_1.svg"; // @ts-ignore
import cardImg2 from "../../assets/home/Card_2.svg"; // @ts-ignore
import cardImg3 from "../../assets/home/Card_3.svg"; // @ts-ignore
import cardImg4 from "../../assets/home/Card_4.svg";
import "./style.css"; // @ts-ignore
import Line1 from "../../assets/home/Line1.svg"; // @ts-ignore

import PeopleBack from "../../assets/ambassador/amblove1.png";
import VideoSection from "../../components/home/VideoSection"; // @ts-ignore

import ReviewImg1 from "../../assets/home/ReviewImg1.svg"; // @ts-ignore
import ReviewImg2 from "../../assets/home/ReviewImg2.svg"; // @ts-ignore
import ReviewImg3 from "../../assets/home/ReviewImg3.svg"; // @ts-ignore
import ReviewImg4 from "../../assets/home/ReviewImg4.svg"; // @ts-ignore
import ReviewImg5 from "../../assets/home/ReviewImg5.svg"; // @ts-ignore
import ReviewImg6 from "../../assets/home/ReviewImg6.svg"; // @ts-ignore
import Header from "../../components/common/Header/header"; // @ts-ignore
import NewHeader from "../../components/common/Header/newHeader";

function Home() {
  const componentRef: any = useRef(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const scrollToComponent = () => {
    componentRef?.current?.scrollIntoView({ behavior: "smooth" });
  };
  // useEffect(() => {
  //   if (shouldScroll && targetRef.current) {
  //     targetRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [shouldScroll, targetRef]);

  const newParadiagmContent = {
    gradientTitle1: "OCTI",
    gradientTitle2: "LEARN",
    subFirstTitle1: "A New Paradigm To Empower",
    subFirstTitle2: "Global Learners",
    secondTitle:
      "OctiLearn empowers every learner to achieve success. Our platform",
    subSecondTitle: "identifies and responds to individual learning gaps.",
  };
  const futureGenContent = {
    firstTitle: "Future Generation",
    subFirstTitle: " Deserve Better",
    secondTitle:
      "The OctiLearn framework reimagines the teaching and learning cycle ",
    subSecondTitle: "to help each student achieve success.",
    gradient: true,
    fontSize: 65,
    top: 0,
    lineTrue: true,
  };
  const tradionalModelContent = {
    firstTitle: "The traditional model of education leads to",
    subFirstTitle: "systemic underachievement",
    secondTitle: "Inherent limitations of the traditional model leave ",
    subSecondTitle: "individual learning gaps unresolved",
    fontSize: 40,
    fontWeight: 600,
    top: -20,
    // lineTrue:true
  };
  const fourStageContent = {
    firstTitle: "A Four-Stage Iterative Process for",
    subFirstTitle: "Continuous Evolution",
    secondTitle:
      "The learner cyclically moves through interconnected stages, overcoming personal learning gaps",
    subSecondTitle: "through timely, targeted academic support.",
  };
  const stemVisualizationContent = {
    firstTitle: "Formalizing STEM Visualizations To Foster ",
    subFirstTitle: "Tomorrow's Innovators",
    secondTitle:
      "The learner cyclically moves through interconnected stages, overcoming personal learning gaps",
    subSecondTitle: "through timely, targeted academic support.",
    gradient: true,
    lineTrue: true,
  };
  const learnersContent = {
    secondTitle:
      "We are building OctiLearn to revolutionize education, with learners",
    subSecondTitle: "taking center stage.",
  };

  const peoplesContent = {
    firstTitle: "People love us",
    subFirstTitle: "and it shows.",
    secondTitle: "Outdated government systems have been in the",
    subSecondTitle: "spotlight as we struggled with a global pandemic.",
  };

  const cards = [
    {
      title: "Standardized ",
      subtitle: "Teaching & Learning",
      para: "Individual learning needs are ignored",
    },
    {
      title: "Infrequent, uniform",
      subtitle: "testing",
      para: "Individual learning gaps are not targeted",
    },
    {
      title: "No use of ",
      subtitle: "performance data",
      para: "Individual learning gaps remain hidden",
    },
    {
      title: "Delayed, Inadequate  ",
      subtitle: "Intervention",
      para: "Lack of timely academic support",
    },
  ];

  const cards1 = [
    {
      image: "cardImages/cardImage1.png",
      title: " Individualized Learning",
      subtitle: "",
      para: "Gain the deeper understanding and learn faster by fully personalizing your content.",
    },
    {
      title: "Adaptive Assesments ",
      subtitle: "",
      para: "Acclerate improvement with ongoing assessments that automatically target your learning gaps",
    },
    {
      title: "Performance Analytics",
      subtitle: "",
      para: "Allocate time and effort intelligently with analytics that clearly highlight your strengths and limitations",
    },
    {
      title: "Targeted Intervention",
      subtitle: "",
      para: "Overcome your learning gaps with targeted academic support based on performance data ",
    },
  ];
  return (
    <div className="home-content">
      {/* Galaxy Animation  */}
      <HeroSection scrollToComponent={scrollToComponent} />
      <div className="header-main-container">
        <div
          className="header-title-container"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-offset="100"
        >
          <div className="header-title">
            The traditional model of education leads to
            <br />
          </div>
          <div className="colored-title">systemic underachievement</div>
        </div>
        <div
          className="subtitle-container"
          style={{ maxWidth: "577px" }}
          data-aos="fade-up"
          data-aos-delay="600"
          data-aos-offset={"100"}
        >
          <p className="traditional-model-subHeading">
            Inherent limitations of the traditional model leave individual
            learning gaps unresolved
          </p>
        </div>
        <div
          className="traditional-bottom-line"
          data-aos="fade-up"
          data-aos-delay="600"
          data-aos-offset={"100"}
        ></div>
      </div>
      <div>
        <div className="card-item-margin">
          <div className="row">
            {cards.map((card) => {
              return <Card {...card} />;
            })}
          </div>
        </div>
      </div>

      {/* Octilearn a future generation */}
      <div className="future-container">
        <NewHeader
          titleClassName="future-font"
          classname="header-main-container"
          offset="100"
          title="Future Generations"
          coloredTitle="Deserve Better"
          subtitle="OctiLearn offers a new way to learn more effectively, creating a more prosperous future"
          titleFontSize="80px"
          subWidth="600px"
        />
      </div>

      {/* Octilearn a new paradiagm */}
      <div className="stage-content  paradiagm-content">
        <ContentBox {...newParadiagmContent} />
        <div
          className="d-flex justify-content-center paradiagm-button"
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-duration="800"
        >
          <Button
            onClick={() => { window.open('https://airtable.com/appp24e4POBGM4X9o/shrr3sIvV2Wjr2osF', '_blank', 'noopener') }}
            className="secondary-button item-margin paradigm-button"
            hoverClassName="secondary-button:hover-effect" // Specify the hover class
            text="Join Our Waitlist"
            marginTop={40}

          />
          {/* <a
            href="https://airtable.com/appp24e4POBGM4X9o/shrr3sIvV2Wjr2osF"
            target="_blank"
            rel="noreferrer"
          >
           
          </a> */}
        </div>
        <div className="paradiagm-img-box" />
        <div className="position-relative paradiagm-img">
          <img className={`atom-img`} src={"/atom.png"} alt="octi-learn" />
        </div>
      </div>

      {/* Octilearn Iterative process  */}
      <div className="">
        {/* <ContentBox {...fourStageContent} /> */}
        {/* <Header classname='header-main-container' offset="100" title="A Four-Stage Iterative Process for" coloredTitle="Continuous Evolution" subtitle="Inherent limitations of the traditional model leave individual learning gaps unresolved" titleFontSize="80px" subWidth="577px" /> */}
        <div className="header-main-container">
          <div
            className="header-title-container"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-offset="100"
          >
            <div className="header-title four-font">
              A Four-Stage Iterative Process for
              <br />
            </div>
            <div className="colored-title four-font">Continuous Evolution</div>
          </div>
          <div
            className="subtitle-container"
            style={{ maxWidth: "577px" }}
            data-aos="fade-up"
            data-aos-delay="900"
            data-aos-offset={"100"}
          >
            OctiLearn interconnects key components to overcome limitations of
            traditional learning
          </div>
        </div>
      </div>
      <IterativeProcess />
      <div ref={componentRef} className="card-item-margin">
        <div className="row mt-5">
          <div
            className="col-xl-3 col-lg-3 col-md-3 col-6 "
            data-aos="fade-right"
            data-aos-delay={screenWidth > 576 ? "200" : "200"}
            data-aos-offset={screenWidth > 576 ? "400" : "200"}
          >
            <div className="img-t">
              <img
                src={cardImg}
                className="card-img-top"
                alt="Alt text for the image"
              />
              <div className="card-content">
                <h1 className="title">Individualized learning</h1>
                <div className="pink-line"></div>
                <p className="subtitle-cards">
                  Gain deeper understanding and learn faster by fully
                  personalising your content
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-xl-3 col-lg-3 col-md-3 col-6"
            data-aos="fade-right"
            data-aos-delay={screenWidth > 576 ? "300" : "200"}
            data-aos-offset={screenWidth > 576 ? "400" : "200"}
          >
            <div className="">
              <img
                src={cardImg2}
                className="card-img-top"
                alt="Alt text for the image"
              />
              <div className="card-content">
                <h1 className="title">Adaptive Assessments</h1>
                <div className="pink-line"></div>
                <p className="subtitle-cards">
                  {" "}
                  Accelerate improvement with ongoing assessments that target
                  your learning gaps
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-xl-3 col-lg-3 col-md-3 col-6"
            data-aos="fade-right"
            data-aos-delay={screenWidth > 576 ? "400" : "200"}
            data-aos-offset={screenWidth > 576 ? "400" : "200"}
          >
            <div className="">
              <img
                src={cardImg3}
                className="card-img-top"
                alt="Alt text for the image"
              />
              <div className="card-content">
                <h1 className="title">Performance Analytics</h1>
                <div className="pink-line"></div>
                <p className="subtitle-cards">
                  Allocate time and effort with analytics that clearly highlight
                  your strengths and limitations
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-xl-3 col-lg-3 col-md-3 col-6"
            data-aos="fade-right"
            data-aos-delay={screenWidth > 576 ? "500" : "200"}
            data-aos-offset={screenWidth > 576 ? "400" : "200"}
          >
            <div className="">
              <img
                src={cardImg4}
                className="card-img-top"
                alt="Alt text for the image"
              />
              <div className="card-content">
                <h1 className="title">Targeted Intervention</h1>
                <div className="pink-line"></div>
                <p className="subtitle-cards">
                  Overcome your learning gaps with targeted academic support
                  based on performance data
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <JoinUs />
      <div className="join-us-mobile">
        <div className="join-octi-learn">
          <img className="degree-img" src="/join-1.png" alt="degreeImg"></img>
          <div className="join-today-text">
            {/* <h2>Join OctiLearn Today!</h2> */}
            <p>Join OctiLearn Today!</p>
            <div className="join-text-p">
              <p className="join-text">
                Experience the future of learning with our personalized and
                adaptive ecosystem
              </p>
            </div>
            <div className="tradional-bottom-line-new"></div>
          </div>
          <div className="mobile-responsive-ball">
            <div className="ball-pair">
              <div className="small-ball">
                <span className="tick">&#10003;</span>
              </div>
              <p className="personalizrd-text">Personalized Resources</p>
            </div>
            <div className="ball-pair">
              <div className="small-ball">
                <span className="tick">&#10003;</span>
              </div>
              <p className="personalizrd-text">Learn Faster</p>
            </div>
          </div>
          <div className="higher-ball">
            <div className="round-ball">
              <span className="tick-check">&#10003;</span>
            </div>
            <p className="personal-text">Score Higher</p>
          </div>
          {/* <div className="score-higher-ball">
              <div className="small-ball"><span className="tick">&#10003;</span></div>
              <p className="personalizrd-text">Score Higher</p>
            </div> */}
          <div className="ball-container">
            <div className="ball-pair">
              <div className="small-ball">
                <span className="tick">&#10003;</span>
              </div>
              <p className="personalizrd-text">Personalized Resources</p>
            </div>
            <div className="ball-pair">
              <div className="small-ball">
                <span className="tick">&#10003;</span>
              </div>
              <p className="personalizrd-text">Learn Faster</p>
            </div>
            <div className="ball-pair">
              <div className="small-ball">
                <span className="tick">&#10003;</span>
              </div>
              <p className="personalizrd-text">Score Higher</p>
            </div>
          </div>
          <div className="ball-container-1">
            {/* <div className="ball-pair">
              <div className="small-ball"><span className="tick">&#10003;</span></div>
              <p className="personalizrd-text">Score Higher</p>
            </div> */}
          </div>
          <div className="join-now-btn">
            <button className="now-btn">Join Us Now</button>
          </div>
          <div className="join-bulb-container">
            <img className="join-bulb" src="/join-2.png"></img>
          </div>
          <div className="book-container">
            <img className="book-img" src="/join-3-3.png"></img>
          </div>
        </div>
      </div>
      {/* Octilearn Learners  */}
      <div className="my-5 learners">
        <div className="my-5">
          {/* <ResponsiveImg width={800} src="/learner.png" /> */}
        </div>
        <div className="d-flex justify-content-center">
          <img className="learner-img" src="/learner.png" />
        </div>

        <div className="learners-bottom-line mb-0"></div>
        <div className="learnerTextcontainer">
          <p className="learnerText">We are building OctiLearn to revolutionize education, with learners taking center stage.</p>
        </div>
        {/* <ContentBox {...learnersContent} top={0} /> */}

        <div
          className="d-flex justify-content-center"
          data-aos="fade-up"
          data-aos-offset="100"
          data-aos-duration="600"
        >
          <a
            href="https://airtable.com/appp24e4POBGM4X9o/shrr3sIvV2Wjr2osF"
            target="_blank"
            rel="noreferrer"
          >
            <Button
              className="secondary-button"
              text="Join Our Waitlist!"
              marginTop={100}
            />
          </a>
        </div>
      </div>

      {/* Octilearn People love us  */}
      {/* <div className="peoples-container">
        <div className="my-5 peoples">

                    <div className="peoples-sub1" style={{}}>
                      <div className="peoples-header-sub">
                        <p className="peoples-header-text1">People Love Us and <span className="peoples-header-text2"> It Shows </span></p>
                      </div>
                      <div className="peoples-subheader-sub">
                        <p className="peoples-subheader-text">Outdated government systems have been in the spotlight as we struggled with a global pandemic.</p>
                      </div>
                      <div className="peoples-line-sub">
                        <img className="people-line-pic" src={Line1} />
                      </div>
                    </div>
                    <div style={{position:"relative",display:"flex",width:"100%"}} className="peoples-sub2">
                      
                      <img src={PeopleBack} style={{position:"absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)",zIndex:0, height:"80%"}} />
                      
               
                      <div style={{width:"50%",display:"flex",alignItems:"", justifyContent:"space-around",flexDirection:"column"}}>
                        <div>     
                    <ReviewCard title={"George Alvarado"} desgination={"National Institute"} description={"Outdated government systems have been in the spotlight as we struggled with a global pandemic"} marginTop={"5%"} marginBottom={""} marginLeft={"0%"} marginRight={""} stars={"3"} image={ReviewImg1}  />
                    </div>   
                    <div>              
                      <ReviewCard title={"George Alvarado"} desgination={"National Institute"} description={"Outdated government systems have been in the spotlight as we struggled with a global pandemic"} marginTop={""} marginBottom={""} marginLeft={"5%"} marginRight={""} stars={"3"} image={ReviewImg2}    />
                      </div>    
                      <div>             
                      <ReviewCard title={"George Alvarado"} desgination={"National Institute"} description={"Outdated government systems have been in the spotlight as we struggled with a global pandemic"} marginTop={""} marginBottom={"15%"} marginLeft={"10%"} marginRight={""} stars={"3"} image={ReviewImg3}    />
                      </div>               
                      </div>
                      <div style={{width:"50%",display:"flex",alignItems:"", justifyContent:"space-around",flexDirection:"column"}}>     
                      <div>                 
                    <ReviewCard title={"George Alvarado"} desgination={"National Institute"} description={"Outdated government systems have been in the spotlight as we struggled with a global pandemic"} marginTop={"5%"} marginBottom={""} marginLeft={"30%"} marginRight={""} stars={"3"} image={ReviewImg4}    />
                    </div>   
                    <div>              
                      <ReviewCard title={"George Alvarado"} desgination={"National Institute"} description={"Outdated government systems have been in the spotlight as we struggled with a global pandemic"} marginTop={""} marginBottom={""} marginLeft={"25%"} marginRight={""} stars={"3"} image={ReviewImg5}    />
                      </div>   
                      <div>              
                      <ReviewCard title={"George Alvarado"} desgination={"National Institute"} description={"Outdated government systems have been in the spotlight as we struggled with a global pandemic"} marginTop={""} marginBottom={"15%"} marginLeft={"35%"} marginRight={""} stars={"3"} image={ReviewImg6}    />
                      </div>               
                      </div>
                  



                    </div>


                     


        </div>
      </div> */}
    </div>
  );
}
export default forwardRef(Home);
