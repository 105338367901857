import React from "react";
import "./InspirationBehind.css"; // @ts-ignore
import line from "../../../assets/home/FutureGenLine.png";
import AboutArrow from "../../../assets/About/AboutArrow.png"; // @ts-ignore
import Purdue from "../../../assets/About/Purdue.png";
import Stem from "../../../assets/About/Stem.png";
import OctiLearn from "../../../assets/About/®.png";
const InspirationBehind = (props: any) => {
  return (
    <div className="InspirationBehind-container">
      <div data-aos-offset="800">
        <p
          className="InspirationBehind-text1"
          data-aos="fade-down"
          data-aos-offset="700"
          data-aos-duration="3000"
          data-aos-easing="ease-in-shine"
        >
          The inspiration behind{" "}
          <span className="InspirationBehind-text1-gradient">Octilearn</span>
        </p>
      </div>
      <div className="InspirationBehind-sub2">
        <p
          className="InspirationBehind-text2"
          data-aos="fade-up"
          data-aos-offset="600"
          data-aos-duration="3000"
          data-aos-easing="ease-in-shine"
        >
          <span style={{ fontWeight: 700, color: "#fff" }}>Abubakar Sial,</span>{" "}
          a School of Aeronautics and Astronautics organization, decided to
          transition his focus from aeronautics to education, intending to pass
          on his engineering acumen and problem-solving expertise to students.
          After nearly a decade in the private education sector, he found that
          traditional educational frameworks were significantly restricting
          students’ academic progress..
        </p>
      </div>
      <div className="InspirationBehind-sub4">
        <img
          className="InspirationBehind-Line"
          style={{ width: "253px" }}
          src={line}
          data-aos="zoom-in"
          data-aos-offset="500"
          data-aos-duration="3000"
          data-aos-easing="ease-in-shine"
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "56px",
        }}
      >
        <div>
          <img
            src={Purdue}
            data-aos="fade-right"
            data-aos-offset="400"
            data-aos-duration="3000"
            data-aos-easing="ease-in-shine"
          />
        </div>
        <div
          data-aos="zoom-out"
          data-aos-offset="400"
          data-aos-duration="3000"
          data-aos-easing="ease-in-shine"
        >

          <img src={Stem} />

        </div>
        <div>
          <img
            src={OctiLearn}
            data-aos="fade-left"
            data-aos-offset="400"
            data-aos-duration="3000"
            data-aos-easing="ease-in-shine"
          />
        </div>
      </div>
      <div className="InspirationBehind-sub3">
        <p
          className="InspirationBehind-text3"
          data-aos="fade-in"
          data-aos-delay="300"
          data-aos-duration="800"
        >
          Exasperated with the inflexibility, uniformity, and ineffectiveness of
          traditional teaching methods, Abubakar adopted a systems-thinking
          approach. He sought to develop a dynamic platform to improve student
          learning—a setting where students could not only learn more
          effectively but also gain more practical knowledge for real-world
          development and breakthroughs. That was the beginning of{" "}
          <span style={{ fontWeight: 700, color: "#fff" }}>OctiLearn.</span>
        </p>
      </div>
    </div>
  );
};

export default InspirationBehind;
