import React from "react";
import "./WhatWeDo.css";
import WhatWeDoCard from "../../About/Cards/WhatWeDoCard/Index";
import Involve from "../../../assets/About/InvolvePic.png";
import OurApproach from "../../../assets/About/OurApproachPic.png";
import ProblemPic from "../../../assets/About/ProblemPic.png";
import CommitmentPic from "../../../assets/About/CommitmentPic.png";
import { ContentBox } from "../../home";
const WhatWeDoData = {
  firstTitle: "What we do?",
  subFirstTitle: "",
  secondTitle:
    "Inherent limitations of the traditional model leave individual learning ",
  subSecondTitle: "gaps unresolved",
  gradient: false,
  fontSize: 50,
  top: 0,
  lineTrue: false,
};

const WhatWeDoData2 = {
  Value1: {
    title: "Problem",
    description:
      "Exasperated with the inflexibility, uniformity, and ineffectiveness of traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic platform to improve student learning—a setting where students could not only learn more effectively but also gain more practical",
    image: ProblemPic,
    linetrue: true,
    BackNumber: "1",
  },
  Value2: {
    title: "Our Approach",
    description:
      "Exasperated with the inflexibility, uniformity, and ineffectiveness of traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic platform to improve student learning—a setting where students could not only learn more effectively but also gain more practical",
    image: OurApproach,
    linetrue: true,
    BackNumber: "2",
  },
  Value3: {
    title: "Our Commitment to innovation",
    description:
      "Exasperated with the inflexibility, uniformity, and ineffectiveness of traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic platform to improve student learning—a setting where students could not only learn more effectively but also gain more practical",
    image: CommitmentPic,
    linetrue: true,
    BackNumber: "3",
  },
  Value4: {
    title: "How to get involved?",
    description:
      "Exasperated with the inflexibility, uniformity, and ineffectiveness of traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic platform to improve student learning—a setting where students could not only learn more effectively but also gain more practical",
    image: Involve,
    linetrue: false,
    BackNumber: "4",
  },
};
const WhatWeDo = (props: any) => {
  return (
    <div className="WhatWeDo-Container">
      <div className="WhatWeDo-sub1">
        <ContentBox {...WhatWeDoData} />
      </div>
      <div className="WhatWeDo-sub2">
        <div className="WhatWeDoCard1"  data-aos="fade-up" data-aos-duration="1000">
          <WhatWeDoCard {...WhatWeDoData2.Value1}  />
        </div>
        <div className="WhatWeDoCard2" data-aos="fade-up" data-aos-duration="2000">
          <WhatWeDoCard {...WhatWeDoData2.Value2} />
        </div>
        <div className="WhatWeDoCard3" data-aos="fade-down" data-aos-duration="2000">
          <WhatWeDoCard {...WhatWeDoData2.Value3} />
        </div>
        <div className="WhatWeDoCard4" data-aos="fade-down" data-aos-duration="3000">
          <WhatWeDoCard {...WhatWeDoData2.Value4} />
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
