import { useEffect, useRef, useState } from "react";
import { IterativeIcon } from "../../../assets/icons/";

import "./style.css";// @ts-ignore
import cycleImg from "../../../assets/home/newcycle.svg";
import iterativetextContainer from "./iterativeContainer";

function IterativeProcess() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
console.log("screenWidth*0.0893", screenWidth ,screenWidth*0.0893)
  return (<>
    <div className="cycle-container">
      {iterativetextContainer({ delay: 400, offset: 0, pointnum: 1, text: "Individual Learning", className: "first-container1", textClassname: "text-1 text-1-margin", style:{marginBottom:`${(screenWidth*0.035)}px`} })}
      <div className="cycle-mid">
        <div className="middle-img-container">
          <div className="middle-img">
            <img className="octi-img logo-mid octi-learn-pic" src="/octi-learn.svg" alt="OctiLearn" />
            <img
            style={{width:`${screenWidth*0.25}px`,height:`${screenWidth*0.25}px`}}
              className="icon-container arrow-img"
              src="/arrows.png"
              alt="OctiLearn"
              data-aos="zoom-in"
              data-aos-delay="1400" data-aos-offset="300"
            />
          </div>
        </div>
        <div className="inner-mid-cycle">
          {iterativetextContainer({ delay: 1100, offset: 450, pointnum: 4, text: "Targeted Intervention", className: "first-container", textClassname: "text-1", style:{marginRight:`${(screenWidth*0.035)}px` }})}
          {iterativetextContainer({ delay: 800, offset: 200, pointnum: 2, text: "Ongoing Adaptive Testing", className: "first-container", textClassname: "text-1", style:{marginLeft:`${(screenWidth*0.300)}px` }})}
        </div>
      </div>
      <div className="cycle-mid-mobile">
        <div className="cycle-container">
         <img src={cycleImg}/>
        </div>
       
      </div>
      {iterativetextContainer({ delay: 800, offset: 100, pointnum: 3, text: "Performance Analytics", className: "first-container1", textClassname: "text-1", style:{marginTop:`${(screenWidth*0.035)}px`} })}
    </div>
  </>
  );
}

export default IterativeProcess;
